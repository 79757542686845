import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-regex-terms',
  templateUrl: './regex-terms.component.html',
  styleUrls: ['./regex-terms.component.css']
})
export class RegexTermsComponent implements OnInit {
  public regexTerms = [];

  public newTerm = {
    pretty_name: '',
    notes: '',
    regex: '',
    title_weight: '',
    description_weight: '',
    ignore_case: false
  }

  public searchBox;

  constructor(public state: StateService) {
    // set the leads
    this.state.getRegexTerms().subscribe(data => {
      this.regexTerms = data;
    })
  }


  ngOnInit() {
    this.state.generateRegexTerms();
  }


  // deletes a regex term
  public deleteTerm(term, idx) {
    let data = { url: "regex", params: `?regex_id=${term.id}` };

    this.state.request(2, data).subscribe((data) => {
      // reload terms upon succ
      this.state.generateRegexTerms();
    });
  }


  // adds new regex term
  public async addNewTerm() {
    // do form err checking
    var errs = "";
    for (let k in this.newTerm) {
      if (k == "title_weight" || k == "description_weight") {
        if (typeof this.newTerm[k] == "string")
          errs += `Please make sure ${k} is filled out.\n`;
      }

      if (k != "ignore_case") {
        if (this.newTerm[k].length == 0)
          errs += `Please make sure to fill out ${k}`
      }
    }

    if (errs.length > 0) { alert(errs); return false; }

    let data = { url: "regex", params: { params: Object.values(this.newTerm) } };

    this.state.request(1, data).subscribe((data) => {
      // reload the terms
      this.state.generateRegexTerms();

      // reset form upon succ
      for (let k in this.newTerm) { this.newTerm[k] = "" }
      this.newTerm.ignore_case = false;
    });

  }

}
