import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';


import { AppComponent } from './app.component';
import { LeadsTableComponent } from './components/leads-table/leads-table.component';
import { FilteringPipe } from './pipes/filtering.pipe';
import { RegexTermsComponent } from './components/regex-terms/regex-terms.component';

@NgModule({
  declarations: [
    AppComponent,
    LeadsTableComponent,
    FilteringPipe,
    RegexTermsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
