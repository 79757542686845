import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  private urls = environment;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    })
  };

  constructor(private http: HttpClient) { }


  // get requests
  public getRequest(params): Observable<any> {
    let url = this.urls.url + this.urls[params.url];
    return this.http.get(url);
  }

  // post requests
  public postRequest(params): Observable<any> {
    let url = this.urls.url + this.urls[params.url];
    return this.http.post(url, params.params);
  }

  // delete requests
  public deleteRequest(params): Observable<any> {
    let url = this.urls.url + this.urls[params.url] + params.params;
    return this.http.delete(url, params.params);
  }

}
