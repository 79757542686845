import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';

declare var $: any;

@Component({
  selector: 'app-leads-table',
  templateUrl: './leads-table.component.html',
  styleUrls: ['./leads-table.component.css']
})
export class LeadsTableComponent implements OnInit {
  public leads = [];
  public selectedLead = { url: "" };
  public pagination = { page: 1, pageSize: 5, collectionSize: 0 }
  public leadsSorter = { load_time: -1, lead_id: 1, score: 1, title: 1, };
  public searchBox: string;
  public minScore: any;

  constructor(private state: StateService) {
    // set the leads
    this.state.getLeads().subscribe(data => {
      this.state.allLeads = data;
      this.pagination.pageSize = this.pagination.collectionSize = data.length;
      this.refreshLeads();
    })

    this.leads = this.state.allLeads;
  }


  ngOnInit() {
    // $("#startDatepicker").datepicker()
    // $("#endDatepicker").datepicker()
    // generate leads
    this.state.generateLeads();
  }


  // refresh leads table & reset the pagination
  public refreshLeads() {
    var slice = (this.pagination.page - 1) * this.pagination.pageSize
    this.leads = this.state.allLeads
      .map((lead, i) => ({ id: i + 1, ...lead }))
      .slice(slice, slice + this.pagination.pageSize);

    this.pagination.collectionSize = this.state.allLeads.length;
  }


  // handles ignore & contact
  public async leadAction(lead, idx, action) {
    let data = {
      url: action,
      params: {
        lead_id: lead.lead_id
      }
    };

    var removeLeadCase = {
      ignore: true,
      contact: true
    }

    var res = await this.state.request(1, data).toPromise();
    if ('id' in res) {
      if (removeLeadCase[action]) {
        this.state.allLeads.splice(idx, 1);
        this.refreshLeads();
      }
    }
  }


  // set current working lead
  public selectLead(lead) {
    this.selectedLead = lead;
  }

  public filterLeads(min, max, field) {
    var leads = JSON.parse(JSON.stringify(this.state.allLeads));
    this.leads = leads.filter((lead) => {
      return lead[field] >= min && lead[field] <= max;
    });
  }


  // handles table sorting & filtering
  public sortLeads(attr, range) {
    // filtering
    if (range) {
      var max, min;

      if (attr == "load_time") {
        var start = (<HTMLInputElement>document.getElementById('startDatepicker')).value;
        console.log(start)
        var end = (<HTMLInputElement>document.getElementById('endDatepicker')).value;

        if (start.length == 0 || end.length == 0) {
          alert('Please make sure to selecte a start & end date.');
          return false;
        }

        var _start = start.split('/');
        start = _start[2] + '-' + _start[0] + '-' + _start[1];

        var _end = end.split('/');
        end = _end[2] + '-' + _end[0] + '-' + _end[1];

        if (start > end) { max = start; min = end; }
        else { min = start; max = end; }

        this.leadsSorter[attr] *= -1;
      }

      if (attr == "score") { min = this.minScore; max = 100; }

      this.filterLeads(min, max, attr);

    }


    var leads = JSON.parse(JSON.stringify(this.state.allLeads));

    // sorting
    leads.sort((a, b) => {
      if (a[attr] < b[attr])
        return 1 * this.leadsSorter[attr]
      if (b[attr] < a[attr])
        return -1 * this.leadsSorter[attr]

      return 0;
    });

    this.leads = leads;
    this.leadsSorter[attr] *= -1;
  }

}
