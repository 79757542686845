import { Injectable } from '@angular/core';
import { RequestsService } from './requests.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  public leads = new BehaviorSubject([]);
  public regexTerms = new BehaviorSubject([]);

  public allRegexTerms = [];
  public allLeads = [];

  public login = true;

  constructor(private rqt: RequestsService) { }

  // fake spoof login
  public loginUser(creds) {
    if (creds.username == "leadsuser" && creds.password == "leadspass")
      this.login = true;
    else
      alert('Incorrect username or password');
  }

  // grab top leads from backend
  public generateLeads() {
    this.request(0, { url: "topLeads" }).subscribe((data) => {
      this.setLeads(data.id);
    });
  }

  // grab top leads from backend
  public generateRegexTerms() {
    this.request(0, { url: "regex" }).subscribe((data) => {
      this.setRegexTerms(data.regex);
    });
  }

  // sort leads by date desc & set leads to behavior subject
  public setLeads(leads) {
    leads.sort((a, b) => {
      return (a.load_time < b.load_time) ? 1 : ((b.load_time < a.load_time) ? -1 : 0);
    });

    this.leads.next(leads);
  }

  // sort leads by date desc & set leads to behavior subject
  public setRegexTerms(terms) {
    this.regexTerms.next(terms);
  }

  // get behavior subject
  public getLeads() {
    return this.leads;
  }

  // get behavior subject
  public getRegexTerms() {
    return this.regexTerms;
  }

  // does http request
  public request(t, params) {
    if (t == 0) // do get rqt
      return this.rqt.getRequest(params);

    if (t == 1)// do post rqt
      return this.rqt.postRequest(params);

    if (t == 2)
      return this.rqt.deleteRequest(params);
  }

}
