import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { StateService } from './services/state.service';
import { LeadsTableComponent } from './components/leads-table/leads-table.component';
import { RegexTermsComponent } from './components/regex-terms/regex-terms.component';

declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  entryComponents: [
    LeadsTableComponent,
    RegexTermsComponent,
  ]

})
export class AppComponent {
  @ViewChild('loadComponent', { static: false, read: ViewContainerRef }) entry: ViewContainerRef;
  public nav = [
    {
      route: "Leads Table",
      options: "leadsTable",
      component: LeadsTableComponent
    },
    {
      route: "Regex Terms",
      options: "regexTerms",
      component: RegexTermsComponent
    },
  ];


  title = 'leads';

  public userLogin = {
    username: '',
    password: ''
  }

  constructor(private resolver: ComponentFactoryResolver, public state: StateService) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.entry.clear();
      const factory = this.resolver.resolveComponentFactory(LeadsTableComponent);
      const componentRef = this.entry.createComponent(factory);
    }, 0);
  }

  public login() {
    var msg = "";
    for (let key in this.userLogin) {
      if (this.userLogin[key].length <= 0) {
        msg += key + " is missing.\n";
      }
    }

    if (msg.length > 0) {
      alert(msg);
    } else {
      this.state.loginUser(this.userLogin);
    }
  }

  public renderView(view) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(view.component);
    const componentRef = this.entry.createComponent(factory);
  }
}
